@import '../../../styles/preset';

.menu {
  position: relative;
  box-shadow: 4px 4px 17px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  border: none !important;
  z-index: $f-dropdownmenu-z-index !important;
  // .arrow {
  //   display: block;
  //   position: absolute;
  //   border-radius: 2px;
  //   right: -8px;
  //   top: 20px;
  // }

  .item {
    font-size: $f-primary-font-size;
    line-height: $f-primary-line-height;
  }  
}

.toggle {
  @extend %no-button;
}
