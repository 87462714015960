@import '../../../styles/preset';

.btn-radio {
  input {
    display: none;
  }
  label {
    color: $f-blue;

    @include hover-focus() {
      cursor: pointer;
    }
  }
  input:checked + label {
    background-color: $f-blue;
    color: $f-white;
  }
}

.btn-radios {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border: 1px solid $f-blue;
  border-radius: 4px;
  width: auto;
}
