@import '../../../styles/preset';


.deleteIconContainer {
  @extend %no-button;
  background-color: $f-red !important;
  @include hover-focus() {
    background-color: $f-red !important;
  }
  width: 37px;
  height: 32px;
  border-radius: 0.25rem;
  svg {
    color: $f-white;
  }
}


   