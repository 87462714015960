@import '../../../styles/preset';

.button {
  margin-right: 0.5rem;
  background: $f-ultra-light-gray !important;
  border: 1px solid $f-light-gray !important;
  color: $f-dark-blue !important;
  border-radius: 4px;
}

.icon {
  display: inline-block;
}
