@import '../../../../styles/preset';


.comments {
  background-color: $f-white;
  border: 1px solid $f-light-gray;
  max-height: 600px;

  @include media-breakpoint-up(md) {
    // width: 400px;
  }

  .addCommentSection {
    background-color: $f-white;
    bottom:0;
    border-top: 1px solid $f-light-gray;
    position: sticky;
  }
}