@import '../../../styles/preset';

.fullscreen-wrapper {
  @extend %fullscreen-wrapper;
}

.main {
  margin: 0 auto;
  max-width: 432px;
  max-height: 464px;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 24px 32px;
  color: $f-black;
  text-align: center;

  h1 {
    margin-top:0;
  }
  a {
    margin-top:1rem;
  }
}
