@import './colors';


.title {
  color: $f-dark-blue;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 28px;
  padding: 0 0 5px 40px;
  margin-left: -9px;
}

.tableHeader {
  background-color: $f-light-gray;
  color: $f-dark-blue;
  font-size: 30px;
}
 