@import '../../../styles/preset';
 
.profile-menu {
  .toggle {
    padding-top: 0;
    padding-bottom: 0;
    @extend %no-button;

    &.btn-secondary {
      @extend %no-button;
    }

    font-size: 14px;
    line-height: 23px;
  }

  .username {
    margin-right: 10px;
  }

  .avatar {
    width: 24px;
    height: 24px;
  }

  .notification {
    width: 24px;
    height: 24px;
  }
}
