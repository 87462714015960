@import '../../../styles/preset';

$margin-default: 15px;

.folder {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  // border-radius: 4px;
  width: 272px;
  height: 96px;
  color: $f-black-blue;
  padding: 15px 0 15px 0;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.folderIcon {
  margin: 0 0 0 $margin-default;
}

.title {
  margin: 0 0 0 $margin-default;
}

.notIsFavoriteIcon {
  margin: 0 $margin-default 0 0;
  path {
    transition: fill ease-out 0.2s;
    stroke: $f-blue;
  }
}

.isFavoriteIcon {
  margin: 0 $margin-default 0 0;
  stroke: $f-blue;

  path {
    position: absolute;
    transition: fill ease-out 0.2s;
    fill: rgb(255, 242, 54);
    stroke-width: 0.8;
    stroke: $f-blue;
  }
}

.has-notification {
  position: absolute;
  margin: 0 0 0 35px;
}

