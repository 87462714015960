@import './colors';
@import './sizes';

.main-content {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.highlightSelect {
  padding: 0;
  background-color: $f-light-yellow;
}


.fieldsContainer {
  // max-width: 500px;
  h4 {
    color: $f-black;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 16px;
  }
}

.mainTitle {
  color: $f-dark-blue;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  border-right: 1px solid $f-ultra-light-gray;
}


.statusViewDetails {
  border-top-left-radius: 0;
  border-bottom-right-radius: 99999px;
  padding: 4px 10px 4px 5px;
  color: $f-ultra-light-gray
}
