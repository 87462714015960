@import '../../../styles/preset';

.circle {
font-size: 13px;
line-height: 14px;
padding: 0 4px;
min-width: 18px;
height: 18px;
text-align: center;
color: #fff;
border-radius: 18px;
 }

.counter-without-count {
  @extend .circle;
  min-width: 11px;
  height: 11px;
}

.grey {
  border: 2px solid $f-gray;
  background-color: $f-gray;
  opacity: 0.7; 
}

.red {
  border: 2px solid $f-red;
  background-color: $f-red;
}