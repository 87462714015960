
.linkWrapper {


  .link {
    .linkText {
      font-size: 14px;
      letter-spacing: normal;
     
     }
  }
  

}



