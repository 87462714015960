@import '../../../../../styles/preset';

.myFilterWrapper {
  width: 100%;
  line-height: 13px;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  outline: 1px solid $f-light-gray !important;
  margin-bottom: -1px;
  padding: 7px;

  &:hover,
  &:focus {
    outline: 4px solid $f-light-blue !important;
  }

  .myFilterText {
    flex: 1;
    margin: 0 1px;
    color: $f-dark-blue;
    padding: 0.7rem 1rem;
    font-weight: bold;
  }

  .buttonDelete {
    width: 30px;
    z-index: 1;

    &:hover {
      transition: 1s;
      transform: scale(1.1);
    }
  }
}
