div .wrapper-switch-pager {
  margin-left: 0 ;
   margin-right: 0 ;
  }

.switch-pager {
padding-left: 0;
padding-right: 0;
}

