@import '../../../styles/preset';

.header {
  background-color: $f-blue;
  height: 48px;
  z-index: 30;
  .backLink {
    color: $f-white;
    display: block;
    @include hover-focus() {
      color: $f-white;
      text-decoration: none;
    }
  }
}
