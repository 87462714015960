@import '../../../styles/preset';


.title {
  font-style: normal;
  font-weight: normal;
  line-height: 14px;
  color: $f-gray;
}

.value {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: $f-dark-blue;
}
