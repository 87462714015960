@import '../../../styles/_preset.scss';

%my-placeholder {
  color: $f-dark-gray;
}

.lightGrey {
  background-color: $f-ultra-light-gray;
}

.Unruled {
  color: $f-black;
  background-color: $f-ultra-light-gray;
}

.Select div %placeholder {
  [class*='placeholder'] {
    @extend %my-placeholder;
  }
}

.Date {
  input {
    &::placeholder {
      @extend %my-placeholder;
    }
  }
}

.Default {
  label div span{
    &::placeholder {
      @extend %my-placeholder;
    }
  }
}