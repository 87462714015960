

 .container  {
  height: 100%;
  background-color: rgb(59, 58, 58);
  background-size: 50px 50px;
  display: flex;
 
  > * {
    height: 100%;
    min-height: 100%;
    width: 100%;

  }

  background-image: linear-gradient(
      0deg,
      transparent 24%,
      rgba(255,255,255, 0.05) 25%,
      rgba(255,255,255, 0.05) 26%,
      transparent 27%,
      transparent 74%,
      rgba(255,255,255, 0.05) 75%,
      rgba(255,255,255, 0.05) 76%,
      transparent 77%,
      transparent
    ),
    linear-gradient(
      90deg,
      transparent 24%,
      rgba(255, 255, 255, 0.05) 25%,
      rgba(255, 255, 255, 0.05) 26%,
      transparent 27%,
      transparent 74%,
      rgba(255,255,255, 0.05) 75%,
      rgba(255,255,255, 0.05) 76%,
      transparent 77%,
      transparent
    );
;


 }
