.accept {
  border-color: #107c10 !important;
}

.reject {
  border-color: #d83b01 !important;
}


.container-dropzone{
  width: 100%;
  height: 100%;

  text-align: center;
  padding: 20px;
  border: 3px  #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;

  margin-bottom: 20px;
  .dropzone {
    width: 100%;
    height: 100%;
    span {
      font-size: 2rem;
      color: #777;
    }
    text-align: center;
    padding: 20px;
    border: 3px dashed #eeeeee;
    background-color: #fafafa;
    color: #bdbdbd;
  
    margin-bottom: 20px;
  }
}