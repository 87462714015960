@import '../../../styles/preset';


.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  word-break: normal;
  input {
    &:first-of-type {
      text-overflow: ellipsis;
    }}
  .icon {
    cursor: pointer;

    &:hover {
      transform: scale(1.2);
      /* Увеличиваем масштаб */
    }
  }
}



.buttonCloseContainer {


  text-align: center;

  button {
    width: 100%;
  }

  span {
    letter-spacing: 0.08em;
  }
}

 



.container-backlight {
  @extend .container;
  @extend .borderBackLight;
}