.addButton {
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  border-left: 1px solid #FFFFFF !important;
  padding: 0 0 0 7px;
  height: 100%;
  margin-left: 15px;
}
