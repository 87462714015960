@import '../../../styles/preset';
.container-backlight {
  width: 100%;

  @extend .borderBackLight  
}
.my-select {
  white-space: normal;
  min-height: 46px;
  width: 100%;
}

.my-select-has-value {
  @extend .borderBackLight;
  white-space: normal;
  min-height: 46px;
  border-radius: 0;
}


