.enter {
  opacity: 0;
  transform: translateY(-100%);
}

.enterActive {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
}

.exit {
  opacity: 1;
  transform: translateY(200%);
}

.exitActive {
  opacity: 0;
  transform: translateY(0);
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
}