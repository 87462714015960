.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.exit {
  opacity: 1;
  transform: translateX(0);
}

.exitActive {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 200ms ease-in-out, transform 300ms ease-in-out;
}
