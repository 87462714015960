// Prefix "f" means "flow", the project name.
// We have to prefix the variables in order to not override Bootstrap's variables.

$f-light-blue: #D6F1F7;
$f-blue: #49A6C8;
$f-ultra-light-blue: #2684ff; // aka grey ultra light

$f-city-blue: #367A91;
$f-dark-blue: #2F496A;
$f-black-blue: #18284D;
$f-black: #3C3E41;
$f-dark-gray: #616469; // aka grey hard
$f-gray: #ACB5C4; // aka grey mid
$f-light-gray: #D6DCE2; // aka grey light
$f-ultra-light-gray: #F5F6F8; // aka grey ultra light
$f-white: #FFFFFF;
$f-red: #C84958;
$f-green: #49C89A;
$f-light-green: #E3F5EF;
$f-purple: #8149C8;
$f-yellow: #C8BB49;
$f-light-yellow: #fdf7d8;
