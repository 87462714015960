

.columnControllerPk {
  width: 8%
}

 
 
.columnControllerBillingAccount {
  width: 30%

}

.columnControllerIsClosed {
  width: 8%

}

.columnControllerDatetimeInstalled {
  width: 10%

}

.columnControllerDatetimeActive {
  width: 10%

}
