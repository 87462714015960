@import '../../../../components/tables/table';
@import '../../../../styles/preset';

.print-source {
  display: none;
}

body > .print-source {
  display: block;
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  @page {
    size: landscape !important;
    margin: 12mm;
  }
}

.thTaskPk { 
  width: 5%;
}

.thAuthor {
  width: 7%;
  height: 100%;
}

.thSupervisor {
  width: 7%;
  height: 100%;
}

.thAccount {
  width: 7%;
  height: 100%;
}

.thType {
  width: 8%;
  height: 100%;
}

.thStatus {
  width: 7%;
  height: 100%;
}

.thIsPruned {
  width: 4%;
}

.thEditedDate {
  width: 7%;
}

.thCreatedDate {
  width: 7%;
}

.thDuedate {
  width: 6%;
}

// .thDescription {
// }

.thParentTask {
  width: 7%;
}

.thExternalRequest {
  width: 7%;
}

// .thComment {
// }

.thDontActive {
  background-color: $f-ultra-light-gray
}