
$timeout: 600ms;
$list-item-max-height: 66px;


.exit {
  max-height: $list-item-max-height;
  opacity: 1;
  transition: ease-in;  
}

.exitActive {
  max-height: 0;
  opacity: 0;
  transition: all $timeout ease-out;   

}

