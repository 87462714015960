%no-button {
  &, &:hover, &:focus, &:active {
    border:none !important;
    background-color: transparent !important;
    outline: none !important;
    appearance: none !important;
    box-shadow: none !important;
    cursor: pointer;
  }
}

%no-textarea {
  border: none;
  overflow: auto;
  outline: none;
  box-shadow: none;
  resize: none; 
}

%fullscreen-wrapper {
  background-color: $f-ultra-light-gray;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  height: 100vh;
}

@mixin status-label {
  color: $f-dark-gray;
  border: 1px solid lighten($f-light-gray, 0.2);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  &::before {
    content: "";
    background-color: $f-dark-gray;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    margin-right: 7px;
    padding: 3px;

  }
}

@mixin status-label-white {
  color: $f-white;
  border: 1px solid lighten($f-light-gray, 0.2);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  
  &::before {
    content: "";
    background-color: lightyellow;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    margin-right: 7px;
    padding: 3px;
  }
}

@mixin status-label-color($color) {
  border: none !important;
  color: $f-black !important;
  background-color: rgba($color, 0.2) !important;
  &::before {
    background-color: $color !important;
  }
}


.borderBackLight {
 
  border-color:$f-ultra-light-blue;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 0 0 1px $f-ultra-light-blue;
    .form {
      display: block;
    }
    .title {
      display: none;
    }
  }




