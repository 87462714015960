@import '../../styles/_variables';

.buttonCSVLink {
  text-decoration: none;
  color: white;

  &:hover {
    text-decoration: none;
    color: white; 

  }

  &:active {
    text-decoration: none;
    color: white;
  }
}

 .trActive {
  color: #212529;
  background-color: rgba(117, 117, 117, 0.075);
 }

 .trActiveColor {
  filter: brightness(80%) contrast(120%);
 
}


 .trArchive {
  filter: grayscale(0.8);

}


.resizer {
  $padding-border: 0px;
  border-right: none;
  // position: absolute;
  // top: 0;
  // right: 0;
  width: 0.1px !important;
  padding: $padding-border !important;
  cursor: col-resize;
  user-select: none;
  z-index: 100;

  &:before {
    padding: $padding-border + 3px;
    content: '';
    // background-color: red;
    position: absolute;
    z-index: 1;
    height: 44px;
    width: 4px;
    margin: -22px 0px 0px 0px;
  }

  &:hover {
    transition: all linear 100ms;
    border: 1px solid #49A6C8;
    background-color: #49A6C8;
  }

  &:active {
    background-color: $f-blue;
    border-left: 2px solid #49A6C8;
    border-right: 2px solid #49A6C8;
    box-shadow: #0ff;
  }

  @media screen and (max-width: $xl) {
    // display: none;
    padding: 0;
  }
}

.resizer-td {
  @media screen and (max-width: $xl) {
    // display: none;
    padding: 0;

  }

  border: none;
}

.thIcon {
  height: 100%;
  padding: 5px 7px 5px 5px;
 }