@import '../../../styles/preset';

.notification {
  width: 24px;
  height: 24px;
}

.notification-counter {
  position: absolute;
  margin: 0 0 15px 10px;
}

.container-notification {
  margin-right: 10px;
}
