@import '../../../styles/preset';

.accountSearchContainer {
  position: relative;
  .accountSearch {
    position: relative;
    margin: 0;
    .searchField {
      max-width: 232px;
      height: 32px;
      font-size: 14px;
      line-height: 16px;
      padding-left: 30px;
      padding-right: 28px;
      box-shadow: none;
    }
    .searchIcon {
      position: absolute;
      left: 0;
      padding: 8px;
    }
    .clearSearchIcon {
      position: absolute;
      right: 0;
      padding: 8px 10px 8px 8px;
      color: $f-dark-gray;

      svg {
        width: 10px;
      }
    }
    .clearSearchIconPointer {
      cursor: pointer;
      &:hover {
        transition: 1s;
        transform: scale(1.3);
      }
    }
  }
  .results {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: $f-white;
    width: 100%;
    color: $f-dark-gray;
    position: absolute;
    top: 29px;
    left: 0;

    .result {
      display: block;
      padding: 0.5rem;
      &:hover {
        background-color: $f-light-gray;
      }
    }
  }
}

.spinnerContainer {
height: 80px;
align-items: center;
justify-content: center;
}


.spinner {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
