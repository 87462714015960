@import '../../../../styles/preset';

.fieldsContainer {
  h4 {
    color: $f-black;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 15px;
  }
}

