@import '../../../styles/preset';

.actions-icon {
  width: 28px;
}

.toggle {

  @extend %no-button;
}

.menu {
  width: 350px;
  padding:16px 24px !important;
  font-size: $f-primary-font-size !important;
  line-height: $f-primary-line-height !important;
  z-index:1021 !important;

  .header {
    font-weight: $f-dropdown-header-font-weight;
    font-size: $f-dropdown-header-font-size;
    line-height: $f-dropdown-header-line-height;
  }
}
