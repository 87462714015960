@import '../../../../styles/preset';

.container {  border-bottom: 1px solid $f-light-gray !important;

.module {
  @extend %no-button;
  width: 100%;
  padding: 0.7rem 1rem;
  text-align: left;
  color: $f-dark-blue;
  font-weight: bold;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    margin-left: 0.4rem;
  }
  svg {
    transform: rotate(270deg);
  }
  &.open {
    svg {
      transform: rotate(90deg);
    }
  }

 
} &:hover, &:focus {

  outline-offset: 0;
  outline: 0.5px solid $f-gray !important;
}
}