@import "../../../../styles/preset";

.addCommentForm {
  border: 1px solid $f-light-gray;
  border-radius: 4px;

  .addCommentTextarea {
    @extend %no-textarea;
    overflow: hidden;
    min-height: 30px;

    font-size: $f-primary-font-size;
    &:focus {
      box-shadow: none;
    }
  }
  .addCommentButton {
    @extend %no-button;
    background-color: 1px solid $f-red;
    padding: 9px;
  }
}
