@import '../../../../../styles/preset';

.underlines {
  display: flex;
  justify-content: space-between;
  position: relative;}

  $colorShadow: 2px;


.broken .line:nth-child(1) {
  background-color: $f-red;
  box-shadow: 0 0 $colorShadow $f-red
}

.weak .line:nth-child(-n + 2) {
  background-color: $f-yellow;
  box-shadow: 0 0 $colorShadow $f-yellow
}

.medium .line:nth-child(-n + 3) {
  background-color: $f-green;
  box-shadow: 0 0 $colorShadow $f-green;

}

.strong .line {
  background-color: $f-green;
  box-shadow: 0 0 $colorShadow $f-green
}

.line {
  flex: 1; // Занимать равную часть пространства
  height: 5px;
  background-color: $f-light-gray;
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out
}

$distanceBetween: 11px;


.line:nth-child(2) {
margin-left: $distanceBetween;
}

.line:nth-child(3) {
margin-left: $distanceBetween;
}

.line:nth-child(4) {
margin-left: $distanceBetween;
}
