@import '../../../styles/_preset';

  .form-group-text {
    display: flex;
    justify-content: space-between;
  }



.form-group-label {
  opacity: 0.950;
  font-size: large;
}

.cardTitle {
  color: $f-blue;
}

.title {
  color: $f-gray;
}