@import '../../../styles/preset';


.button {
  max-width: 300px;
}

.alert {
  border-radius: 8px;
}

.alertTitle {
  font-size: 1.25rem;
  font-weight: 600;
}

.alertHelpText {
  font-size: 0.9rem;
  color: $f-dark-gray
}

.alertActions {
  justify-content: center;
}

.changeValuesList {
  list-style-type: none;
  padding: 0;
}

.changeItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

.changeName {
  font-weight: bold;
  color: #333;
}

.changePrev,
.changeNew {
  font-size: 0.85rem;
  color: #555;
  margin-left: 1rem;
}
