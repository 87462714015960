@import '../../../styles/preset';

.toggle {
  @extend %no-button;
}

.buttonIcon {
  transition: 0.2s;

  &:hover {
    transform: scale(1.1);
  }
}

.filterContainer {
  width: 80%;
}

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  height: 40px;
}

.center {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;

}

.top-left {

  grid-column: 1;
  grid-row: 1;
  justify-self: end;
  align-self: end;
}

.top-right {
  grid-column: 2;
  grid-row: 1;
  justify-self: start;
  align-self: end;

}

.bottom-left {
  grid-column: 1;
  grid-row: 2;
}

.bottom-right {
  grid-column: 2;
  grid-row: 2;
}
