@import '../../../styles/preset';

.iconContainer {
  @extend %no-button;
  transition: 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.3);
    opacity: 0.8;
    svg {
      transform: scale(1.05);
      transition: 0.4s ease-in-out;
    }
  }
  width: 32px;
  height: 32px;
  border-radius: 4px;
  svg {
    color: $f-white;
  }
}

.deleteIconContainer {
  @extend .iconContainer;
  background-color: $f-red !important;
}

.refundIconContainer {
  @extend .iconContainer;
  background-color: $f-blue !important;
}

.file {
  transition: 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
}
.fileIsNotActive {
  opacity: 0.9;
  border-color: $f-red !important;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
}
