@import '../../../styles/preset';

.breadcrumbs {
  .link {
    color: $f-gray;
    font-weight: bold;
    text-decoration: none;
  }
  .separator {
    color: $f-gray;
    margin-left:5px;
    margin-right: 5px;
  }
  .current {
    color: $f-white;
  }
}


.toggle {
  padding-top: 0;
  padding-bottom: 0;
  @extend %no-button;

  &.btn-secondary {
    @extend %no-button;
  }

  font-size: 14px;
  line-height: 23px;
}
