@import '../../../styles/preset';

div .linkOpenText  {
  font-weight: 800;
  font-size: medium;
  transition: 0.5s !important;
width: 50px;
  &:hover {
    font-weight: 1000;
    font-size: x-large;
  }
}



.textContainer {
  display: flex;
  flex-direction: column;

}

.author {
  color: $f-dark-blue;
}


.text {
  word-break: break-word;
}
