@import '../../../styles/preset';


.wrapperSubHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tableIconsWrapper {
  display: flex;
  flex-direction: row;
}

.buttonViewFilesContainer {
  margin-left: auto;
}



.listItem {
  margin-top: 10px;

}


.text {
  word-break: break-all;
}

// .lastItem {
//   background-image: linear-gradient(0deg,  rgba(0,0,0,0) 0%,  rgba(0,0,0,0) 60%, #000);
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
//   display: inline-block;
// }
