@import '../../../../styles/colors';


.isFavoriteIcon {
  margin: 0 15px 0 0;

  path {
    position: absolute;
    transition: fill ease-out 0.2s;
    fill: rgb(255, 242, 54);
    stroke-width: 0.8;
  }
}


.isFavoriteIcon {
  margin: 0 15px 0 3px;
  stroke: $f-blue;
  cursor: pointer;
  path {
    position: absolute;
    transition: fill ease-out 0.2s;
    fill: rgb(255, 242, 54);
    stroke-width: 0.8;
    stroke: $f-blue
  }
}

.link {
  max-width: 162px;
}
