 @import '../../../styles/_table_grey';
 @import '../../../styles/preset';

  .buttonWrapperAddTransport {
    display: flex;
    margin-right: 55px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  button .textButton {
    width: 32px;
    height: 32px;
    top: 290px;
    left: 855px;
  }

  .description {
    width: 20px;
    margin-left: 10px;
    align-self: center;
    letter-spacing: 0.5px;
   }
 
 
