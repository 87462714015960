@import '../../../styles/preset';

.wrapper {
  background-color: $f-white;
  margin:0;
  border-radius: 0;
  outline-offset: 0;
  outline: transparent inset 1px;

  input {
    &:first-of-type {
      text-overflow: ellipsis;
    }}

    &:focus, &:hover  {
      outline-color: #2684FF;
      border-radius: 4px;
       outline-width: 1px;
      box-shadow: 0 0 0 1px #2684ff;
      outline-offset: 0;
    // .form {
    //   display: block;
    // }
    // .title {
    //   display: none;
    // }
  }
  .icon {
    padding: 8px;
  }
  .title {
    height: 43px;
    margin-top: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .calendar {
    display: none;
    position: absolute;
    top:0;
    left:0;
    width: 100px;
    height: 100px;
  }
}

 
.grey {
  background-color: $f-light-gray;
}

 

.wrapper-backlight {
  @extend .wrapper;
  @extend .borderBackLight;
}
