@import '../../../styles/preset';

.sidebar {
  background-color: $f-dark-blue;
  color: $f-gray;
  width: 224px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  padding: 48px 0 0;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0.44, $f-light-blue),
      color-stop(0.72, $f-dark-blue),
      color-stop(0.86, $f-black-blue)
    );
  }

  ul {
    margin-top: 16px;
    padding-left: 0;
    list-style: none;
  }
  a {
    display: block;
    color: $f-gray;
    text-decoration: none;
    padding: 15px 2px 15px 10px;
    border-left: 4px solid transparent;

    &.active {
      background-color: rgba(255, 255, 255, 0.08);
      border-left: 4px solid $f-green;
    }
    @include hover-focus {
      color: $f-gray;
      text-decoration: none;
    }
  }
  margin-left: -224px;
  // Animate the drawer.
  transition: margin-left 0.3s ease;
  &.open {
    margin-left: 0;
  }

  .secondary-menu {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    // justify-content: flex-end;
  }
}
