@import '../../../../styles/preset';
 
  .title {
    font-style: normal;
    font-weight: normal;
    font-size:  $f-primary-font-size;
    line-height: 16px;
    color: $f-gray;
    word-break: break-word;
  }

 
