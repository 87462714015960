@import '../../../../styles/preset';

.header {
  background-color: #ffffff;

  .title {
    color: $f-dark-blue;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    border-right: 1px solid #f5f6f8;
  }
}