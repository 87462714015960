@import '../../../../components/tables/table';
@import '../../../../styles/variables';
//133

.thPkAndCodeNumber {
  width: 7%;
}

.thCrateDate {
  width: 7%;
}

.thEditedDate {
  width: 7%;
}

.thAuthor {
  width: 8%;
  height: 100%;
}

.thAddress {
  width: 12%;
}

.thBillingAccountAndEvent {
  width: 10%;
}

.thWorkCategoryAndResponsibleDepartment {
  width: 9%;
  height: 100%;
}

 

.thAffiliate {
  width: 9%;
  height: 100%;
}

.thExecutiveImplementersAndImplementers {
  width: 10%;
  height: 100%;
}

.thSupervisor {
  width: 9%;
  height: 100%;
}

.thDuedate {
  width: 9%;
}

.thStatus {
  width: 9%
}

.thIsPruned {
  width: 4%
}

.thParentTask {
  width: 7%
}

.thParentExternalRequest {
  width: 7%
}

.thDescription {
  width: 300px;
}

.bold-text {
  color: $f-dark-blue;
}



.print-source {
  display: none;
}

body>.print-source {
  display: block;
}

@media print {

  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  @page {
    size: landscape !important;
    margin: 12mm;
  }
}

.borderBackLight {
  border-color: #2684ff;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 0 1px #2684ff;

  .form {
    display: block;
  }

  .title {
    display: none;
  }
}