@import '../../../styles/preset';

@mixin bordered($color) {
  border: 1px solid $color !important;

  @include hover-focus() {
    border: 1px solid $color !important;
  }
}

.button {
  @extend %no-button;
  font-size: $f-primary-font-size;
  line-height: $f-primary-line-height;
  border-radius: 4px;
  height: 36px;

  &.bordered {
    @include bordered($f-white);
  }
}

.button_DEFAULT {
  @extend .button;
  color: $f-white;

  &.bordered {
    @include bordered($f-white);
  }
}

.button_GREY {
  @extend .button;
  color: $f-dark-blue;

  &.bordered {
    @include bordered($f-dark-blue);
  }
}

.buttonDisabled_DEFAULT {
  @extend .button;
  color: $f-light-gray;

  &.borderedDisable {
    @include bordered($f-gray);
  }
}

.buttonDisabled_GREY {
  @extend .button;
  color: $f-gray;

  &.borderedDisable  {
    @include bordered($f-gray);
  }
}
