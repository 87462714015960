@import '../preset';

.title {
  font-style: normal;
  font-weight: normal;
  font-size: $f-primary-font-size;
  line-height: 16px;
  color: $f-gray;
  word-break: break-word;
}

.tittleChildrenLink {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: $f-blue;
  padding: 5px;
}

.arrowStyle {
  cursor: pointer;
  color: $f-dark-gray;
  transform: rotateZ(0deg);
  transition: 0.5s !important;
  margin-top: -5px;
  margin-left: 7px;
  border: none !important;
}

  .rotateArrow {
    transform: rotateZ(-180deg);
    transition: 0.5s !important;
  }
