li {
  list-style-type: none;
}

ul {
  padding: 0
}

@media print {
  .pageBreak {
   page-break-before: always;
  } 
 } 