.badge {
  cursor: pointer;
}

.badgesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;  
  align-items: center; 
}
