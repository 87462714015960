

.columnEventPk {
  width: 3%
}

.columnEventName {
 width: 27%
}

.columnEventType {
  width: 30%
 }

.columnEventBillingAccount {
  width: 30%

}

.columnEventIsClosed {
  width: 10%

}

.columnEventDatetimeAdded {
  width: 10%

}

.columnEventDatetimeEdited {
  width: 10%

}
