@import '../../../styles/preset';

.loginFullscreenWrapper {
   @extend %fullscreen-wrapper;
}

.auth-wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 432px;
  max-height: 100%;
}
