@import './styles/full';

body {
  margin: 0;
  font-family: $f-primary-font-family;
  font-size: $f-primary-font-size;
  line-height: $f-primary-line-height;
  background-color: $f-ultra-light-gray;


}

.table {
  background-color: $white;
  table-layout:fixed;
  overflow-wrap: break-word;

  tr {

    th, td {
      vertical-align: middle;
    }

    th {
      &.actions {
        width: $f-th-actions;
        max-width: $f-th-actions;
        text-align: center;
      }
      font-size: $f-primary-font-size;
      line-height: $f-primary-line-height;
      font-weight: normal;
      padding-top:0;
      padding-bottom:0;
      height: 47px;
      border-bottom-width: 1px !important;
      min-width: $xxs;

      &.id {
        width: 90px;
      }
    }
    td {
      font-size: $f-secondary-font-size;
      line-height: $f-secondary-line-height;
    }
  }
}


.btn-radio {
  label {
    background-color: $f-ultra-light-gray;
    font-size: $f-secondary-font-size;
    line-height: $f-secondary-line-height;
  }
  @include hover-focus() {
    input + label {
      background-color: $f-light-green;
    }
  }
  input:checked + label {
    background-color: $f-green;
    color: $f-white;
  }
}

.control-label {
  font-weight: bold;
}
.invalid-feedback {
  display: block;
  color: $f-red;
}
form {
  max-width: 800px;
  .is-invalid {
    .invalid-feedback {
      display: block;
      color: $f-red;
    }
  }
  .is-invalid + .invalid-feedback {
    color: $f-red;
  }

  .form-group.required .control-label:after {
    content:"*";
    color:$f-red;
    margin-left:5px;
  }

  .rc-time-picker {
    &.is-invalid {
      input {
        border: 1px solid $f-red;
      }
    }
  }
  fieldset {
    padding:1rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    background-color: $f-white;
    border: 1px solid $f-ultra-light-gray;
  }
}

.custom-checkbox {
  .custom-control-label {
    padding-top:4px;
    padding-bottom:4px;
    width: 100%;
  }
}

.modal-content {
  .modal-header {
    border-bottom: 1px solid $f-ultra-light-gray !important;

    h5 {
      color: $f-black;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
    }
  }
  .modal-footer {
    border-top: 1px solid $f-ultra-light-gray !important;
  }
}

.pagination {
  margin-bottom: 0;

  .page-item {
    .page-link {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      padding: 0 !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: 0 4px !important;
      color: $f-dark-blue;
      border: 1px solid $f-light-gray;
      box-sizing: border-box;

      &.more {
        font-size: 8px !important;
        letter-spacing: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: center;
        pointer-events: none;
      }
      &.previous {
        margin-right: 20px;
      }
    }
    &:not(.disabled) {
      .page-link {
        &.first, &.previous, &.next, &.last {
          color: $f-blue;
        }
      }
    }
    &.disabled {
      .page-link {
        &.first, &.previous, &.next, &.last {
          color: $f-gray;
        }
      }
    }

    &.active {
      .page-link {
        color: $f-white !important;
      }
    }
  }
}

.separator {
  border-top: 1px solid $f-ultra-light-gray;
}

.react-datepicker__input-container {
  input {
    padding:0;
    width: 100%;
    height: 44px;
    outline:none;
    border: none;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $f-dark-gray;
      opacity: 1; /* Firefox */
    }
  }
}
.react-datepicker-popper {
  z-index:1021 !important;
}

.f-custom-select.is-invalid > div {
  border-color: #dc3545;
}


