
$timeout: 600ms;
$list-item-max-width: 272px;


.exit {
  max-width: $list-item-max-width;
  opacity: 1;
  transition: ease-in;  
}

.exitActive {
  max-width: 0;
  opacity: 0;
  transition: all $timeout ease-out;   

}

