

.columnPk {
  width: 5%;
}

.columnLastName {
  width: 15%;
}

.columnAccessPolicy {
  width: 10%;
}

.columnPosition {
  width: 10%;
}

.columnSupervisor {
  width: 10%;
}

.columnEmail {
  width: 10%;
}

.columnPhoneNumber {
  width: 10%;
}

.columnNickName {
  width: 10%;
}

.columnPersonnelCodeNumber {
  width: 10%;
}

.columnIsDriver {
  width: 7%;
}
