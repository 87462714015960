@import '../../../styles/preset';

.btn-checkbox {
  input {
    display: none;
  }

  label {
    color: $f-black-blue;
    background-color: $f-white;
    border-radius: 3px;
    padding: 1px 5px;
    transition: all 0.3s ease;
    transform: scale(1);

    @include hover-focus() {
      cursor: pointer;
    }

    &::before {
      content: '✔';
      display: block;
      color: transparent;
      transition: all 0.5s ease;
      font-size: 12px;
      transform: scale(1); 
    }
  }

  input:checked + label {
    background-color: $f-blue;
    color: $f-white;
  
    &::before {
      color: $f-white;
      transform: scale(1.2); 
    }
  } 
} 

 