@import '../../../styles/preset';


.line {
  border-bottom: 1px solid $f-gray;
  width: 98%;    margin-left: auto;
  margin-right: auto;
   ; /* Параметры линии */

}

