@import '../../../styles/preset';


.arrowStyle {
  cursor: pointer;
  color: $f-dark-gray;
  transform: rotateZ(0deg);
  transition: 0.5s !important;
  margin-top: -5px;
  margin-left: 7px;
  border: none !important;
}

  .rotateArrow {
    transform: rotateZ(-180deg);

    transition: 0.5s !important;
  }
