@import '../../../styles/preset';
@keyframes changeLetter {
  0% {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    height: 4px;
    width: 100%;
    background-color: white;
    border-radius: 4px 4px 0 0;
  }

  100% {
    content: '';
  }
}
.menu {
  padding: 0;
  width: 100%;
  background-color: $f-white;
  border-top: 1px solid $f-light-gray;
  border-bottom: 1px solid $f-light-gray;
  list-style-type: none;
  margin: 0;

  li {
    .container_content {
      display: flex;
      text-align: center;
    }
    .badge {
      align-self: center;
      opacity: 0.4;
      font-size: 16px;
      margin-left: 3px;
      font-weight: 500;
    }
    display: inline-block;
    .menuLink {
      text-decoration: none;
      cursor: pointer;
      color: $f-dark-blue;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      display: block;
      background-color: transparent;
      position: relative;

      &.active {
        color: $f-dark-blue;
        font-weight: bold;

        &::after {
          animation: changeLetter 0.3s linear alternate;
          position: absolute;
          bottom: 0;
          left: 0;

          content: '';
          height: 4px;
          width: 100%;
          background-color: $f-blue;
          border-radius: 4px 4px 0 0;
          transition: opacity 1s ease;
          -webkit-transition: opacity 1s ease;
        }
      }
    }
  }
}
