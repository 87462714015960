@import '../../../styles/preset';

.wrapper {
  background-color: $f-white;
  margin: 0;
  border-radius: 0;
  outline-offset: 0;
  outline: transparent inset 1px;
  &:focus,
  &:hover {
    outline-color: $f-ultra-light-blue;
    border-radius: 4px;
    outline-width: 1px;
    outline-offset: 0;

    box-shadow: 0 0 0 1px $f-ultra-light-blue;
    .form {
      display: block;
    }
    // .title {
    //   display: none;
    // }
  }

  .title {
    height: 43px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .form {
     width: 100%;
    .keyword {
      padding: 0 4px 0 4px;
      width: 100%;
      height: 44px;
      outline: none;
      border: none;
    }
  }
}

.borderBackLight {
  @extend .wrapper;
  outline-color: $f-ultra-light-blue;
  border-radius: 4px;
  outline-width: 1px;
  box-shadow: 0 0 0 1px $f-ultra-light-blue;;
  .form {
    display: block;
  }
  .title {
    display: none;
  }
}

.grey {
  background-color: $f-light-gray;
}
