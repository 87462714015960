@import '../../../styles/preset';

.login-fullscreen-wrapper {
  @extend %fullscreen-wrapper;
}

.login-form {
  margin: 0 auto;
  width: 100%;
  max-width: 432px;
  max-height: 464px;
  background: #FFFFFF;
  border-radius: 0 0 8px 8px;
  padding: 24px 32px;
  color: $f-black;

  h1 {
    margin-top:0;
  }
}
