@import '../../../../../../components/tables/table';
@import '../../../../../../styles/preset';
.print-source {
  display: none;
}
body > .print-source {
  display: block;
}
@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  @page {
    size: landscape !important;
    margin: 12mm;
  }
}
