@import '../../../styles/preset';

.visibilityIconContainer {
  background-color: $f-white !important;
}

.visibilityIconOff {
  color: $f-blue !important;
}

.visibilityOnIcon {
  color: $f-gray !important;
}

.input-wrapper {
  position: relative;
}

.strengthText {
  font-size: $f-secondary-font-size;
  line-height: $f-secondary-line-height;
  color: $f-dark-gray;
}
