@import '../../../styles/preset';

.header {
  background-color: $f-white;

  .account {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: $f-gray;
    border-right: 1px solid $f-ultra-light-gray;
    max-width: 600px;

    .num {
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 45px;
      color: $f-blue;
    }
  }


  .btn-right { 
    cursor: pointer; 
  } 
} 


