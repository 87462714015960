@import '../../../../styles/preset';


.installations-wrapper {
  border: 1px solid $f-light-gray;
  .installations-scrollable {
    overflow-y: auto;
    height: 210px;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
}