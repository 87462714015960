@import '../../../styles/preset';


.status {
  max-width: 200px;
  border: 1px solid $f-ultra-light-gray;
  border-top-left-radius: 99999px;

  // color: $f-white;
  font-size: $f-primary-font-size;
  line-height: $f-primary-line-height;
  padding: 4px 10px 4px 20px;
  width: auto;

}