@import '../../../../styles/generalStyles/subheaderLinks';

.counter {
  color: $f-dark-gray;
  position: absolute;
  top: -5px; 
  right: -13px;
}

.title-container {
  position: relative;
 
}