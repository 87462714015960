@import '../../../styles/preset';



.fullscreen-wrapper {
  @extend %fullscreen-wrapper;
}

.container {
  background-color: #f8d7da;
  color: $f-red;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

 
.button {
  // background-color: #dc3545;
  // color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}
