@import '../../../styles/preset';

.customTooltip {
  display: none;
  position: absolute;
  top: 50%;
  left: calc(100% + 10px);
  transform: translateY(-50%);

  padding: 10px;
  background-color: $f-white;
  border: 1px solid $f-light-gray;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 999;
  min-width: 200px;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -17px;
    transform: translateY(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: transparent $f-light-gray transparent transparent;
  }
}
