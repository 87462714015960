@import '../../../styles/preset';


.circle {
  clip-path: circle(50%);
}


.circle.xs {
  height: 0.4em;
  width: 0.4em;
}

.circle.s {
  height: 0.6em;
  width: 0.6em;
}

.circle.m {
  height: 0.8em;
  width: 0.8em;
}

.circle.cityBlue {
 background: $f-ultra-light-blue;
}

.circle.black {
  background: $f-black;
 }

.circle.invisible {
  visibility: hidden;
}
