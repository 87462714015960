@import '../../../styles/preset';

@mixin main-content {
  display: flex;
  height: 100vh;
}

@mixin main($mainTop) {
  flex: 1;
  background-color: $f-ultra-light-gray;
  margin-top: $mainTop; // Height of the top bar.
  margin-left: 0;

  // If the screen allows, animate the content.
  @include media-breakpoint-up(sm) {
    transition: margin-left 0.3s ease;

    &.with-sidebar {
      margin-left: 224px;
    }
  }
}


.main-content {
  @include main-content;

  main {
    @include main(50px)
  }
}

.main-content-in-wrapper {
  @include main-content;

  main {
    @include main(0)
  }
}

