@import '../../../../../styles/preset';

.wrapperData {
  align-items: flex-start;

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $f-gray;
    word-break: break-word;
  }
}
