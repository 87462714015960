.wrapper-list-item {
  display: flex;
  align-items: center;
}

.btn-dropdown {
  margin-top: 3px;
  margin-left: 4px;
  transform: rotateZ(90deg);
  transition: 1s !important;

  &:hover {
    transform: rotateZ(0deg);
  }
  &:focus {
    transform: rotateZ(0deg);
  }
}
