@import '../../../styles/preset';

.statusLabel {
  @include status-label;
}

.statusLabelWhite {
  @include status-label-white;
}

.statusLabelText {
display: block;
overflow-wrap: anywhere;
@media screen and (max-width: $xl) {
  overflow-wrap: break-word;

  }
}
