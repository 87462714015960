@import '../../../styles/preset';
.button {
  width: 200px;

}

.buttonIcon {
  width: 14px;
  height: 14px;
}

.rotateIcon {
  transform: rotateZ(-270deg);
  transition: 0.2s !important;
}

.isSelectedButton {
  background-color: $f-city-blue !important;
  color: $f-light-blue !important;
  transition: 0.2s !important;
}

.rotateIconBefore {
  &:hover {
    transform: rotateZ(-270deg) !important;
    transition: 0.2s !important;
  }
}


.verticaLine {
  width: 2px;
  height: 37px;
  background-color: $f-gray;
  margin-left: 10px;
 }
