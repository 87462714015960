@import '../../../styles/preset';

.collapsed {
  border-bottom: 1px solid $f-light-gray !important;
}

.action {
  padding: 0.6rem 1rem 0.6rem 2.8rem !important;
  display: block;
  &.checked {
    background-color: $f-ultra-light-gray;
  }
}

.permissions {
  border: 1px solid $f-light-gray;
  border-radius: 4px;
}


