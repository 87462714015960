
@import '../../../styles/preset';

.field {
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #ced4da !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
  padding: 0.375rem 0.75rem !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  width: 100%;
}

.is-invalid {
  border-color: $f-red !important;
}

.calendarIconContainer {
  background-color: $f-white !important;
}

.calendarIcon {
  color: $f-blue !important;
  & {
    width: 24px;
    height: 24px;
  }
}

.wrapperClassName {
  width: 100%;
}
