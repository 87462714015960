@import '../../../styles/preset';

.login-fullscreen-wrapper {
  @extend %fullscreen-wrapper;
}

.registration-form {
  margin: 0 auto;
  background-color: $f-white;
  width: 100%;
  max-width: 432px;
  max-height: 600px;
  border-radius: 8px;
  padding: 24px 32px;
  color: $f-black;
  h1 {
    margin-top:0;
  }
}
