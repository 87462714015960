@import '../../../styles/preset';



.search {
  width: calc(100% - 30px);
}
.sort {
  width: 30px;
}
