@import '../../../../styles/preset';

.comments {
  display: flex;
  flex-direction: column;
  background-color: $f-white;
  border: 1px solid $f-light-gray;
  max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;

  @include media-breakpoint-up(md) {
    width: 400px;
  }

  .date {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    // font-size: $f-secondary-font-size;
    color: $f-gray;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    top: 0;
    &:before,
    &:after {
      content: '';
      width: 30%;
      height: 1px;
      background-color: $f-light-gray;
      margin: 7px 7px;
    }
  }
}
.header {
  background-color: $f-white;
  color: $f-dark-blue;
  font-weight: bold;
  border-bottom: 1px solid $f-light-gray;
  position: sticky;
  top: 0;
}

@mixin comment($bg-color) {
  background-color: $bg-color;
  border-radius: 4px;
  max-width: 100%;
  width: 80%;
  .commentHeader {
    font-size: $f-secondary-font-size;
    display: flex;

    .author {
      font-weight: bold;
      color: $f-dark-blue;
      display: inline-block;
    }
    .time {
      color: $f-gray;
      display: inline-block;
      margin-left: auto;
    }
  }
  .commentBody {
    font-size: $f-primary-font-size;
    word-break: break-word;
  }
}

.notMyComment {
  @include comment($f-ultra-light-gray);
}

.myComment {
  @include comment($f-blue);
  align-self: flex-end;
  .commentHeader {
    position: relative;
    display: flex;
    .time {
      color: $f-light-gray;
      position: absolute;
      top: 0;
      right: 0;
    }
    .author {
      display: none;
    }
  }
  .commentBody {
    padding-top: 11px;
    color: $f-ultra-light-gray;
  }
}
