@import '../../../../styles/preset';


.title {
  color: $f-dark-blue;
  font-weight: bold;
  font-size: 1.5rem;
  // line-height: 28px;
  padding: 25px 0 0 40px;
  margin-left: -9px;
}

