@import '../../../styles/preset';

.helpIcon {
  @extend %no-button;
  color: $f-blue;
  padding: 0 0 0 0.5rem;
  margin-bottom: 0.5rem;
  svg {
    width: 16px;
    height: 16px;
  }
  display: none;
}

.tooltipWrapper {
  &[x-placement^='bottom'] {
    .tooltipArrow::before {
      border-bottom-color: $f-blue !important;
    }
  }
  &[x-placement^='top'] {
    .tooltipArrow::before {
      border-top-color: $f-blue !important;
    }
  }
  &[x-placement^='left'] {
    .tooltipArrow::before {
      border-left-color: $f-blue !important;
    }
  }
  &[x-placement^='right'] {
    .tooltipArrow::before {
      border-right-color: $f-blue !important;
    }
  }
}

.tooltip {
  background-color:$f-dark-blue !important;
  white-space: pre-wrap;
}
