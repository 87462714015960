@import '../../../../components/tables/table.scss';

.thNumber {
  width: 7%;
}

.thStekAnd1cNumber {
  width:  100px 
}

.thIsClosed {
  width: 90px
}
