@import '../../../../styles/preset';

.icon {
  width:20px;
  max-height: 20px;
}

.separator {
  border-top: 1px solid $f-ultra-light-gray;
}
