@import '../../styles/preset';

.cursorWait {
  cursor: wait;
}

.tableContainer {
  margin-top: 10px;
  width: 97% !important;
}

.tableHeader {
  color: $f-dark-blue;
  font-size: 25px;
  font-weight: 700;
  height: 32px;
  letter-spacing: 0;
  min-height: 32px;
  min-width: 194px;
  width: 194px;
}

.table-tr-notification {
  &:first-of-type {
    height: 48px;
    letter-spacing: 0;
    min-height: 48px;
    min-width: 761px;
    width: 761px;
  }
}

.is-read-notification-tr {
  @extend .table-tr-notification;
  span {
    color: #acb5c4;
    font-weight: 300;
  }
}

.is-unread-notification-tr {
  cursor: pointer;
  span {
    @extend .table-tr-notification;
    color: $f-dark-blue;
    font-weight: 600;
  }
}

.is-loading-notification-tr {
  cursor: wait;
  span {
    @extend .table-tr-notification;
    color: $f-dark-blue;
    font-weight: 600;
  }
}

.marked {
  filter: blur(0.5px);
  color: #2a3947;
  background-color: rgba(98, 111, 255, 0.048);
}

.subHeaderButton {
  height: 0;
  .markedSendButtonIcon {
    transition: 0.5s !important;

    &:hover {
      transform: scale(1.2);
    }
    color: $f-dark-blue;
  }
}

.linkPayload {
  transition: 0.7s !important;

  &:hover {
    transform: scale(1.05) !important;
  }
}
