@import '../../../../../styles/preset';


.prompt {
  font-size: $f-secondary-font-size;
  line-height: $f-secondary-line-height;
}
.ok {
  color: $f-green;
  &::before {
    content: '✓ ';
  }
}
.noOk {
  color: $f-red;
  &::before {
    content: 'X ';
  }
}