@import '../../../styles/preset';

.header {
  background-color: $f-black-blue;
  color: $f-white;
  padding: 9px 0.75rem;
  height: 50px;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  z-index: $f-header-z-index;
  width: 100%;

  .left {
    .menu-toggle {
      @extend %no-button;
    }

    .logo {
      margin-left: 10px;
      color: $f-white;
      text-decoration: none;
    }

    .vline {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .burger-notification-active {
    position: absolute;
    margin: 0 0 13px 13px;
  }
}
