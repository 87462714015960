@import '../../../styles/preset';



.wrapperSubHeader {
  min-height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;       
  .title {
    color: $f-dark-blue;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 28px;
  }

  .buttonWrapperAddTransport {
    display: flex;
    margin-right: 97px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  button .textButton {
    width: 32px;
    height: 32px;
    top: 290px;
    left: 855px;
  }

  .description {
    width: 20px;
    margin-left: 10px;
    align-self: center;
    letter-spacing: 0.5px;
    font-weight: 500;
  }
}